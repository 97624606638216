import React, { useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import http from "../http-common";

const LandingEmailCapture = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);

    try {
      const response = await http.post(
        "/user/leadcapture",
        {
          email,
          source: "cashflowrover_landing_page",
          utm_source: urlParams.get("utm_source"),
          utm_medium: urlParams.get("utm_medium"),
          utm_campaign: urlParams.get("utm_campaign"),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        })

      if (!response?.data?.success) throw new Error("Failed to submit");

      // Show success message
      toast.current.show({
        severity: "success",
        summary: "Welcome aboard!",
        detail: "Check your email to complete registration.",
      });

      // Redirect to registration completion
      window.location.href = `/complete-registration?email=${encodeURIComponent(
        email
      )}`;
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Something went wrong",
        detail: "Please try again.",
      });
    } finally {
      setLoading(false);
    }
  };

  const getUtmParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      utm_source: urlParams.get("utm_source"),
      utm_medium: urlParams.get("utm_medium"),
      utm_campaign: urlParams.get("utm_campaign"),
    };
  };

  return (
    <div className="flex flex-col items-center justify-center max-w-xl mx-auto">
      <form onSubmit={handleSubmit} className="w-full">
        <div className="flex flex-col sm:flex-row gap-2">
          <InputText
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="flex-1 p-3"
            required
            type="email"
          />
          <Button label="Join Now" loading={loading} className="px-6" />
        </div>
      </form>
      <Toast ref={toast} />
    </div>
  );
};

export default LandingEmailCapture;
