import React from 'react';
import Pricing from './helpers/pricing';
import LandingEmailCapture from './LandingEmailCapture';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';


const LandingPage = () => {

    const faqs = [
        {
          question: "Can I cancel my subscription anytime?",
          answer: "Yes! Our monthly plan comes with no long-term commitment. You can cancel anytime with no questions asked."
        },
        {
          question: "What makes your tool different from others?",
          answer: "We focus specifically on rental property analysis with detailed cash flow projections, ROI calculations, and market comparisons. Our tool is designed by real estate investors for real estate investors."
        },
        {
          question: "Do you offer support if I need help?",
          answer: "Absolutely! All plans include email support, and our yearly subscribers get priority support with faster response times."
        }
      ];


      const benefits = [
        {
            title: "Save Countless Hours",
            description: "Analyze properties in minutes, not hours. All the metrics you need in one place.",
            icon: "pi pi-clock"
          },
           {
          title: "Make Data-Driven Decisions",
          description: "Stop guessing. Comprehensive analysis tools help you evaluate properties with confidence.",
          icon: "pi pi-chart-bar"
        },
        
        {
          title: "Maximize Your ROI",
          description: "Identify the most profitable properties with advanced cashflow calculators.",
          icon: "pi pi-dollar"
        }
      ];

return (
        <>
        <div className="p-grid p-nogutter landing-page">
            <div className="p-col-12 p-md-6 p-lg-6 left-section">
                <h1 className="headline">Supercharge Your Next Rental Property Research</h1>
                <p className="description">
                    Discover the best rental properties with ease. Leverage our advanced tools and insights to maximize your cashflow.
                </p>
                <div id="zoho-form" className="zoho-form-container">
                    <LandingEmailCapture />
                </div>  
            </div>    
        </div>        

        {/* Benefits Section */}
        <div className="bg-gray-50 p-1">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-2xl font-bold text-center mb-12 p-4">
          Everything You Need to Make Smart Decisions
        </h2>
        
        {/* Single row on desktop, stack on mobile */}

        <div className="flex flex-col md:flex-row gap-4 md:gap-8 justify-center p-4 md:p-6">
  {benefits.map((benefit, index) => (
    <div key={index} className="flex-1 bg-white p-4 md:p-6 rounded-lg shadow-sm">
      <i className={benefit.icon}></i>
      <h3 className="text-base md:text-lg font-semibold mt-3 md:mt-4 mb-2">
        {benefit.title}
      </h3>
      <p className="text-gray-600 text-xs md:text-sm leading-relaxed">
        {benefit.description}
      </p>
    </div>
  ))}
</div>
      </div>
    </div>

        <Pricing/> 

        {/* FAQs Section */}
      <div className="bg-gray-50 p-4 md:p-6 lg:p-8">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-4">
            Frequently Asked Questions
          </h2>
          <Accordion>
            {faqs.map((faq, index) => (
              <AccordionTab key={index} header={faq.question}>
                <p className="text-gray-600">{faq.answer}</p>
              </AccordionTab>
            ))}
          </Accordion>
        </div>
      </div>   

         {/* Social Proof Section */}
      {/* <div className="p-4 md:p-6 lg:p-8">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-8">
            Trusted by Real Estate Investors Worldwide
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <Card className="p-4">
              <p className="italic text-gray-600 mb-4">
                "This tool has completely transformed how I analyze rental properties. What used to take hours now takes minutes."
              </p>
              <p className="font-bold">John D.</p>
              <p className="text-sm text-gray-500">Real Estate Investor</p>
            </Card>
            <Card className="p-4">
              <p className="italic text-gray-600 mb-4">
                "The ROI calculations and market insights helped me find an incredible deal that I would have missed otherwise."
              </p>
              <p className="font-bold">Sarah M.</p>
              <p className="text-sm text-gray-500">Property Manager</p>
            </Card>
            <Card className="p-4">
              <p className="italic text-gray-600 mb-4">
                "Best investment tool I've used. The cashflow forecasts are spot-on and have helped me build a profitable portfolio."
              </p>
              <p className="font-bold">Mike R.</p>
              <p className="text-sm text-gray-500">Portfolio Investor</p>
            </Card>
          </div>
        </div>
      </div> */}

         {/* Final CTA */}
      <div className="bg-blue-600 text-white p-4 md:p-6 lg:p-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-4">
            Ready to Find Your Next Investment Property?
          </h2>
          <p className="text-xl mb-6">
            Join successful investors who use our tools to make better decisions.
          </p>
          <div className="max-w-md mx-auto">
            <LandingEmailCapture />
          </div>
        </div>
      </div> 
        </>
    );
};

export default LandingPage;
